<template>
  <div class="address-detail">
    <div class="main-left">
      <img :src="datas.icon" alt="" class="icon-img">
      <div class="left-content">
        <div class="main-title">地址</div>
        <div class="main-value">{{datas.address}}</div>
      </div>
    </div>
    <div class="main-right" v-if="datas.balance!=undefined">
     <div class="main-title">资产数</div>
      <div class="main-value">{{datas.balance}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="less" scoped>
.address-detail{
  display: flex;
  justify-content: space-between;
  padding: 32px;
  background: #fff;
  margin-bottom: 16px;
  .icon-img{
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
  .main-left{
    display: flex;
  }
  .main-title{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
  .main-value{
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
  }
}
@media screen and (max-width:1024px){
.address-detail{
  flex-direction: column;
}
.main-right{
  margin-left: 64px;
}
.address-detail{
  padding: 16px;
  .icon-img{
    width: 48px;
    height: 48px;
  }
  .main-title{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
  }
  .main-value{
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }
  }
}
</style>
