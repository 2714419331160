<template>
  <Layout :isSearch="true">
    <!-- <template #home-header>
    <div class="home-main">
       <headerWrap class="heder"></headerWrap>
       <div class="search-head"></div>
    </div>
  </template> -->
    <div :class="{ contain: isFocus }">
      <el-empty
        :image-size="80"
        :image="emptyImg"
        class="empty-class"
        v-if="noResult"
      >
        <template #description>
          <div class="empty-tips">
            搜索不到相关内容,<br />
            请输入正确的地址/交易哈希值/NFT ID
          </div>
        </template>
      </el-empty>
      <div v-else>
        <detailContent
          title="数据详情"
          :datas="detailInfo"
          v-if="detailInfo"
        ></detailContent>
        <detailContent
          title="NFT详情"
          :datas="nftInfo"
          :labelProp="nftDetailConfig"
          v-if="nftInfo"
        >
          <template #right v-if="nftInfo.memberCardBackgroundUrl">
            <div class="ml-24">
              <div
                :style="{
                  'background-image': `url(${nftInfo.memberCardBackgroundUrl})`
                }"
                :class="[
                  nftInfo.nftType == 1 ? 'play-img' : 'nft-img',
                  'nft-bg'
                ]"
              ></div>
            </div> </template
        ></detailContent>
        <addressDetail
          :datas="addressInfo"
          v-if="
            result.queryType === 'adfAddress' ||
              result.queryType === 'nftAddress'
          "
        ></addressDetail>

        <tableList
          title="详细记录"
          :columns="blockColumn"
          :mColumns="mBlockColumn"
          :dataList="dataList"
          :mDataList="mDataList"
          v-if="result.queryType == 'nftId'"
        >
          <template #hash="{scoped}">
            <div
              class="blue text-overflow2"
              @click="toHashDetail(scoped.row)"
            >
              {{ scoped.row.blockHash }}
            </div>
          </template>
        </tableList>

        <tableList
          title="NFT ID列表"
          :columns="nftAddressColumn"
          :mColumns="mNftAddressColumn"
          :dataList="dataList"
          :mDataList="mDataList"
          @reachBottom="load"
          v-if="result.queryType == 'nftAddress'"
        >
          <template #tokenId="{scoped}">
            <div
              class="blue text-overflow2"
              @click="toIdDetail(scoped.row.tokenId)"
            >
              {{ scoped.row.tokenId }}
            </div>
          </template>
          <div slot="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="getData"
              hide-on-single-page
              :current-page.sync="pagination.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size.sync="pagination.size"
              layout="total, sizes, prev, pager, next"
              :total="pagination.total"
            >
            </el-pagination>
          </div>
        </tableList>

        <tableList
          title="详细记录"
          :columns="adfAddressColumn"
          :mColumns="mAdfAddressColumn"
          :dataList="dataList"
          :mDataList="mDataList"
          @reachBottom="load"
          v-if="result.queryType == 'adfAddress'"
        >
          <template #hash="{scoped}">
            <div class="blue text-overflow2" @click="toHashDetail(scoped.row)">
              {{ scoped.row.txId }}
            </div>
          </template>
          <div slot="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="getData"
              hide-on-single-page
              :current-page.sync="pagination.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size.sync="pagination.size"
              layout="total, sizes, prev, pager, next"
              :total="pagination.total"
            >
            </el-pagination>
          </div>
        </tableList>
      </div>
    </div>
  </Layout>
</template>

<script>

import detailContent from '@/components/data/detailContent'
import addressDetail from '@/components/data/addressDetail'
import tableList from '@/components/data/tableList.vue'
import tableConfig from '@/components/data/tableConfig'
import detailConfig from '@/components/data/detailConfig'
import api from '@/api/services/browser'
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'
import Layout from '@/components/layout.vue'

export default {
  components: {
    detailContent,
    addressDetail,
    tableList,
    Layout
  },
  data() {
    return {
      emptyImg: 'https://static-10006892.file.myqcloud.com/points-browser/img/empty_icon.png',
      noResult: false,
      blockColumn: tableConfig.blockColumns,
      mBlockColumn: tableConfig.mBlockColumns,
      nftInfo: null,
      detailInfo: {},
      addressInfo: {},
      nftDetailConfig: detailConfig.nftDetailConfig,
      dataList: [],
      mDataList: [],
      blockHashType: '',

      mNftAddressColumn: [
        {
          prop: 'tokenId',
          header: 'table.nftID',
          slot: 'tokenId'
        },
        {
          prop: 'nftName',
          header: 'table.name',
          width: '154px'
        }
      ],
      nftAddressColumn: [
        {
          prop: 'tokenId',
          header: 'table.nftID',
          width: '888px',
          slot: 'tokenId'
        },
        {
          prop: 'nftName',
          header: 'table.name',
          width: '264px'
        }
      ],
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      mPagination: {
        page: 2,
        size: 10,
        total: 0,
        totalPage: 0
      },
      mAdfAddressColumn: [
        {
          prop: 'txId',
          header: 'table.hashValue',
          slot: 'hash'
        },
        {
          prop: 'type',
          header: 'table.tradeType',
          width: '64px'
        },
        {
          prop: 'txTime',
          header: 'table.tradeTime',
          width: '88px'
        }
      ],
      adfAddressColumn: [
        {
          prop: 'txId',
          header: 'table.hashValue',
          width: '862px',
          slot: 'hash'
        },
        {
          prop: 'type',
          header: 'table.tradeType',
          width: '112px'
        },
        {
          prop: 'txTime',
          header: 'table.tradeTime',
          width: '176px'
        }
      ],
      urlQuery: {}
    }
  },
  watch: {
    result: {
      handler(val) {
        this.resetData()
        this.setIsFocus(false)
        if (!val) {
          this.noResult = true
        } else if (val.queryType) {
          this.noResult = false
          this[`${val.queryType}Fn`]()
        }
      },
      immediate: true,
      deep: true
    },
    query: {
      handler(val) {
        // this.resetData()
        this.pagination = {
          page: 1,
          size: 10,
          total: 0
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState('homeWrap', {
      result: state => state.result,
      query: state => state.query,
      isFocus: state => state.isFocus
    })
  },
  mounted() {
    const query = this.$route.query
    this.urlQuery = query
    this.getData(query)
  },
  methods: {
    ...mapMutations('homeWrap', [
      'setIsFocus'
    ]),
    ...mapActions('homeWrap', [
      'queryData'
    ]),
    toIdDetail(tokenId) {
      this.getData({
        condition: tokenId
      })

      // this.$router.replace(`/nftIdDetail?condition=${encodeURIComponent(tokenId)}`)
    },
    toHashDetail(row) {
      let paramsStr = ''
      if (row.txId) {
        paramsStr = `txId=${row.txId}&`
      }
      this.$router.push(`/blockDetail?${paramsStr}blockHash=${row.blockHash}&type=${this.blockHashType}`)
    },
    getData(data) {
      this.queryData({
        page: this.pagination.page,
        size: this.pagination.size,
        ...data
      })
    },
    async load() {
      if (this.mPagination.page < this.mPagination.totalPage) {
        const data = await api.selectByConditions({
          ...this.urlQuery,
          ...this.query,
          page: this.mPagination.page,
          size: this.mPagination.size
        })
        this.mPagination.page += 1
        this.mPagination.totalPage = Math.ceil(data.total / this.mPagination.size)
        this.mDataList = this.mDataList.concat(data.detailList || [])
      }
    },

    handleSizeChange() {
      this.pagination.page = 1
      this.getData()
    },
    resetData() {
      this.nftInfo = null
      this.detailInfo = null
      this.addressInfo = null
      this.dataList = []
      this.mDataList = []
      this.mPagination.page = 2
      // this.pagination = {
      //   page: 1,
      //   size: 10,
      //   total: 0
      // }
    },
    async getNFTDetail(data) {
      const fn = data.phpNftTokenId ? api.getNFTDetailById : api.getNFTDetailBySymbol
      const param = {
        s_ver: 1
      }
      let result = null
      if (data.phpNftTokenId) {
        param.tokenId = data.phpNftTokenId
      } else {
        param.symbol = data.nftSymbol
      }
      result = await fn({ ...param })
      if (result.nftType == 0) {
        result.cardName = result.nftName
        result.cardDesc = result.desc
        delete result.nftName
        delete result.desc
      }
      if (result && result.originPrice) {
        result.originPrice = result.nftType == 1 ? `${result.originPrice} ADF` : `￥ ${result.originPrice / 100}`
      }

      this.nftInfo = result
    },
    nftIdFn() {
      const result = this.result
      if (result.phpNftTokenId || result.nftSymbol) {
        this.getNFTDetail(result)
      }

      this.dataList = result.detailList || []
      this.mDataList = result.detailList || []
      this.blockHashType = result.blockHashType
    },
    nftAddressFn() {
      const result = this.resultadfAddressColumn
      this.dataList = result.detailList || []
      this.mDataList = result.detailList || []
      this.pagination.total = result.total
      this.mPagination.totalPage = Math.ceil(result.total / this.mPagination.size)
      this.addressInfo = {
        address: result.address,
        icon: 'https://static-10006892.file.myqcloud.com/points-browser/img/nft_address_icon.png'
      }
    },
    adfAddressFn() {
      const result = this.result
      this.dataList = result.detailList || []
      this.mDataList = result.detailList || []
      this.pagination.total = result.total
      this.mPagination.totalPage = Math.ceil(result.total / this.mPagination.size)
      this.addressInfo = {
        balance: result.balance,
        address: result.address,
        icon: 'https://static-10006892.file.myqcloud.com/points-browser/img/adf_address_icon.png'
      }
      this.blockHashType = result.blockHashType
    },
    adfHashFn() {
      const result = this.result
      this.detailInfo = result.detail || {}
    },
    nftHashFn() {
      const result = this.result
      const detail = result.detail || {}
      this.detailInfo = detail
      if (result.phpNftTokenId || result.nftSymbol) {
        this.getNFTDetail(result)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.empty-class {
  height: 580px;
  background: #ffffff;
  .empty-tips {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}
::v-deep .el-pagination {
  padding: 24px 0 0;
}
.node-test {
  display: block;
}
.home-main {
  margin-bottom: 80px;
}
.search-head {
  position: fixed;
  top: 0;
  left: 0;
  height: 98px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: #0070ff;
  display: none;
}
@media screen and (max-width: 1024px) {
  .heder {
    display: none;
  }
  .search-head {
    display: block;
  }
  .contain {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 2;
    }
  }
}
</style>
